import { T } from '@sonnen/shared-i18n/customer';
import { Card, CardNotification, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+components';
import { getFormattedDate } from '+legacy/helpers/dates';
import { getKiloPowerObj } from '+legacy/helpers/numbers';
import { fillEmptyValueWithDash } from '+legacy/helpers/strings';
import { BatteryOperatingMode, SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { BatteryCardGrid } from '../BatteryCardGrid';

import { formatNumber } from '+app/utils/number.util';
import batterieImage from '+images/sonnenbatterie.png';

interface Props {
  batteryData: Battery;
  liveData: SiteLive;
}
const renderNotification = (liveData: SiteLive) => {
  const { online, timestamp } = liveData;

  if (!timestamp) {
    return (
      <CardNotification icon={<Icon.QuestionMark />}>
        <Translate
          value={T.batterySystem.notifications.loadingData}
          dangerousHTML={true}
        />
      </CardNotification>
    );
  }
  if (!online) {
    return (
      <CardNotification dataTestId="sonnen-batterie-overview-system-details-offline" icon={<Icon.Offline />}>
        <Translate
          value={T.batterySystem.notifications.notOnline}
          date={getFormattedDate(timestamp, T.general.date.longWithTime)}
          dangerousHTML={true}
        />
      </CardNotification>
    );
  }
  return (
    <CardNotification
      dataTestId="sonnen-batterie-overview-system-details-online"
      type="success"
      icon={<Icon.Checkmark />}
    >
      <Translate
        value={T.batterySystem.notifications.everythingOk}
        dangerousHTML={true}
      />
    </CardNotification>
  );
};

const OperatingModesApiToLocalesMap: Record<BatteryOperatingMode, string> = {
  'Battery-Module-Extention': T.dashboard.operatingModes.moduleExtension,
  'Energy Reserve': T.dashboard.operatingModes['Energy Reserve'],
  'Full-Charge': T.dashboard.operatingModes.fullCharge,
  'Final-Inspection': T.dashboard.operatingModes.finalInspection,
  'Intelligent-Mode': T.dashboard.operatingModes.intelligentMode,
  Manual: T.dashboard.operatingModes.Manual,
  'Not-Defined': T.dashboard.operatingModes.invalidMode,
  'Peak-Shaving': T.dashboard.operatingModes.invalidMode,
  'PV-Simulation': T.dashboard.operatingModes.PVSimulation,
  'Self-Consumption': T.dashboard.operatingModes['Self-Consumption'],
  'Time of Use': T.dashboard.operatingModes['Time of Use'],
  'time-of-use': T.dashboard.operatingModes['Time of Use'],
  'US-Automatic': T.dashboard.operatingModes.automatic,
  'US-Backup-Power': T.dashboard.operatingModes.backupPower,
  'US-Net-Zero-Pv':  T.dashboard.operatingModes.netZeroPV,
  'US-time-of-use':  T.dashboard.operatingModes['Time of Use'],
  'US-Time-Of-Use': T.dashboard.operatingModes['Time of Use'],
  Optimization: T.dashboard.operatingModes.optimization,
};

const mapOperatingModeToTranslation = (mode: BatteryOperatingMode | undefined) => {
  if (!mode) { return 'Self-Consumption'; }
  return OperatingModesApiToLocalesMap[mode] || mode;
};

const { title, model, capacity, operatingMode, serialNumber } = T.batterySystem.overview.details;

export const BatteryCardInfo = ({
  batteryData,
  liveData,
}: Props) => {
  const batteryCapacity = getKiloPowerObj(batteryData.batteryCapacity, 'Wh');

  return (
    <Card title={I18n.t(title)} footerContent={renderNotification(liveData)}>
      <BatteryCardGrid
        side={<img src={batterieImage} alt="sonnenBatterie" />}
        content={(
          <>
            <SpecsItem
              dataTestId="sonnen-batterie-overview-system-details-model"
              title={I18n.t(model)}
              value={fillEmptyValueWithDash(BatteryHelper.getBatteryProductName(batteryData))}
            />
            <SpecsItem
              dataTestId="sonnen-batterie-overview-system-details-capacity"
              title={I18n.t(capacity)}
              value={`${formatNumber({ fixed: false, precision: 1 })(batteryCapacity.value)}
                ${' '}
                ${batteryCapacity.unit}`}
            />
            <SpecsItem
              dataTestId="sonnen-batterie-overview-system-details-operating-mode"
              title={I18n.t(operatingMode)}
              value={I18n.t(mapOperatingModeToTranslation(liveData.batteryOperatingMode))}
            />
            <SpecsItem
              dataTestId="sonnen-batterie-overview-system-serial-number"
              title={I18n.t(serialNumber)}
              value={fillEmptyValueWithDash(batteryData.serialNumber)}
            />
          </>
        )}
      />
    </Card>
  );
};
